.App {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 1rem; /* Add some gap between cards */
}

.contest-card {
  flex: 1 1 calc(25% - 1rem); /* 25% width minus the gap */
  box-sizing: border-box;
  padding: 1rem;
  margin: 0.5rem 0; /* Adjust top and bottom margin as needed */
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.contest-card h3, .contest-card h4 {
  margin: 0;
  padding: 0.5rem 0;
}

.contest-details {
  margin-top: 1rem;
}

.market-header, .market-row {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
}

.market-header strong, .market-row strong {
  flex: 1;
  text-align: left;
}

.market-row span {
  flex: 1;
  text-align: center;
}
