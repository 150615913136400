.league-div {
  margin-bottom: 20px;
  width: 100%;
  padding-left: 10px;
}

.league-div h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.contest-cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.contest-card {
  position: relative;
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
  box-sizing: border-box;
  text-align: left;
  display: flex;
  flex-direction: column;
  flex: 0 1 22%; /* Ensures each card takes 22% of the container width, allowing room for margins */
  min-width: 220px; /* Minimum width to ensure proper display */
  max-width: 22%; /* Maximum width to ensure 4 cards in a row with some gap */
  word-wrap: break-word; /* Ensures text wraps within the card */
}

.info-button {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #007bff;
  color: white;
  text-align: center;
  cursor: pointer;
}

.contest-name {
  font-weight: bold;
  white-space: normal; /* Ensures text wraps within the element */
}

.jurisdiction-counts {
  display: flex;
  justify-content: space-around; /* Distributes the columns evenly */
  align-items: center; /* Vertically centers content */
  font-size: 14px;
  padding: 2px;
}

.jurisdiction-header, .jurisdiction-column {
  flex: 1; /* Ensures that each column takes up equal space */
  text-align: center; /* Centers the text inside each column */
}
.jurisdiction-column.highlight {
  background-color: rgba(255, 0, 0, 0.1); /* Faint red background for highlight */
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: 20px auto; /* Adjust margin to ensure some space from the top */
  padding: 20px;
  border: 1px solid #888;
  width: 90%;
  max-width: 90%;
  max-height: 80vh; /* Max height to ensure it doesn't extend beyond viewport */
  overflow-y: auto; /* Enable vertical scrolling if content is too tall */
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.outcome-table {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
}

.outcome-header {
  font-weight: bold;
  border-bottom: 1px solid #ccc;
}

.outcome-row {
  padding: 5px;
}



.contest-start-time {
  font-size: 0.9em;
  margin-top: 5px;
  color: #555;
}
